<template>
  <div class="form">
    <h1 class="h3 font-weight-bold mb-6">
      {{ $t('title') }}
    </h1>
    <p class="mb-8">
      {{ $t('subtitle') }}
    </p>
    <CyButton
      theme="secondary"
      variant="tertiary"
      :to="{ name: 'login', params: $route.params }">
      {{ $t('back') }}
    </CyButton>
  </div>
</template>

<script>
export default {
  name: 'CyPageMfaInvalidLoginLink',
  i18n: {
    messages: {
      en: {
        title: '@:routes.MFAInvalidLoginLink',
        subtitle: 'The link you used is not valid. Try login again to request an new login link.',
        back: 'Back to login',
      },
      es: {
        title: '@:routes.MFAInvalidLoginLink',
        subtitle: 'El enlace que utilizó no es válido. Intente iniciar sesión nuevamente para solicitar un nuevo enlace de inicio de sesión.',
        back: 'Volver al inicio de sesión',
      },
      fr: {
        title: '@:routes.MFAInvalidLoginLink',
        subtitle: `Le lien que vous avez utilisé n'est pas valide. Essayez de vous connecter à nouveau pour générer un nouveau lien de connexion.`,
        back: 'Retour à la connexion',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin: auto;
  text-align: center;
}
</style>
